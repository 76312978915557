import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from '@aragon/ui';
import { ProfileDocuments } from '../../../containers';
import { ProfileInfo, ProfileReferrals, Tabs } from '../../../components';
import { ProfileStatus } from '../../../components/investor';
import { Title2, Row } from '../../../ui';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { kycApi } from '../../../api/kyc';
import { ENABLED_STATUSES_INVESTOR } from '../../../utils/static/kyc';
import { COLORS } from '../../../utils/static/colors';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from '../../../store/investor';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { VenomContext } from '../../../app/venomAuth/VenomProvider';

export const InvestorProfile = () => {
  const dispatch = useDispatch();
  const venomConnect = useContext(VenomContext);
  const { investor } = useSelector((state) => state);
  const { t } = useTranslation();
  const { state } = useLocation();
  const [selected, setSelected] = useState(state?.lastProfileTab || 0);
  const { data } = useQuery(['getQuestionnaires'], kycApi.getQuestionnaires);

  useEffect(() => {
    dispatch(investorActions.setLastProfileTab(selected));
  }, [selected]);


  const activeQuestsCount = useMemo(() => {
    if (data && data.data) {
      return data.data.reduce(
        (result, item) => result + (ENABLED_STATUSES_INVESTOR[item.status] ? 1 : 0),
        0
      );
    }
  }, [data]);


  const venomAuth = async () => {
    if (!venomConnect) {
      return;
    }

    venomConnect.connect();
  };


  return (
    <Container>
      <Header
        primary={<Title2>{t('Profile')}</Title2>}
        secondary={!investor.venomPublicKey ? <Button onClick={venomAuth} type={'primary'}>Add venom wallet</Button> : null}
      />

      <Tabs
        items={[
          t('Personal data'),
          <Row nowrap>
            {t('Questionnaires')}{' '}
            {activeQuestsCount !== undefined && (
              <Circle active={selected === 1}>{activeQuestsCount}</Circle>
            )}
          </Row>,
          t('Documents'),
          t('Referrals')
        ]}
        selected={selected}
        onChange={setSelected}
      />

      {selected === 0 && <ProfileInfo />}

      {selected === 1 && <ProfileStatus />}

      {selected === 2 && <ProfileDocuments />}

      {selected === 3 && <ProfileReferrals />}
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 32px;
  max-width: 1200px;
  margin: 0 auto;
`;
const Circle = styled.div`
  border: 1px solid ${(p) => (p.active ? COLORS.aragonBlue : COLORS.greyLight)};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  transition: 0.2s;
`;
