import React from 'react';
import { Radio as AragonRadio, GU } from '@aragon/ui';
import { Body3Light } from '../../../ui/typography';
import styled from 'styled-components';


export const Radio = ({ key, label, ...props }) => {
  return (
    <Label key={key}>
      <StyledRadio {...props} />
      <Body3Light>{label}</Body3Light>
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  margin-right: ${6 * GU}px;
  :last-child {
    margin-right: 0;
  }
`;
const StyledRadio = styled(AragonRadio)`
  margin: 0 ${GU}px 0 0;
  flex-shrink: 0;

  ~ div {
    position: relative;
    top: 2px;
  }
`;
