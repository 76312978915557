import React, { useContext, useState } from 'react';
import { Modal, Typography } from 'antd';
import { Input } from 'antd';
import { investorApi, investorKeys, investorReferralApi } from '../../../../api/investor';
import { MessageContext } from '../../../../app/MessageProvider';
import { queryClient } from '../../../../app/QueryClientProvider';
import { getErrorMessage } from '../../../../utils/helpers/common';
import { Container } from '../../../../ui/layout';
import { useTranslation } from 'react-i18next';

export const AddAgentModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const messageApi = useContext(MessageContext);

  const makeRequest = async () => {
    if (code) {
      try {
        await investorReferralApi.requestSetAgent(code);

        messageApi.success(t('Request sent'));
        setCode('');
        onClose();
      } catch (e) {
        console.error(e);
        messageApi.error(t('Something went wrong'));
      }
    } else {
      messageApi.warning(t('Please enter referral code'));
    }
  };

  return (
    <Modal open={open} onCancel={onClose} title={t('Set agent')} onOk={makeRequest} okText={t('Make request')}>
      <Container marginBottom={4}>
        <Typography.Text>{t('Set agent request')}</Typography.Text>
      </Container>
      <Input
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder={t('Enter agent code')}
      />
    </Modal>
  );
};
