import React, { useEffect } from 'react';
import { GU } from '@aragon/ui';
import { FormInput } from '../../components';
import { LinkButton } from '../../../../ui';
import styled from 'styled-components';
import { sendEmailCode } from '../../serverInteraction';
import {CodeCountdown} from "../../../../components";

export const VerifyEmail = ({ form, otherData }) => {

  return (
    <div>
      <Subtitle>
        The verification code has been sent on your e-mail:
        <br />
        <LinkButton>{otherData?.email}</LinkButton>
      </Subtitle>

      <FormInput
        form={{
          ...form,
          rules: { required: 'Required' }
        }}
        label={'Verification code'}
        type={'number'}
        wide
      />

      <RepeatSendTitle>
        <CodeCountdown sendCode={sendEmailCode} isInitialCall={true}/>
      </RepeatSendTitle>
    </div>
  );
};

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
