import React from 'react';
import { GU } from '@aragon/ui';
import { useController } from 'react-hook-form';
import { UploadDocumentsBox } from '../../../../components/common';
import { Body3Light } from '../../../../ui/typography';
import { COLORS } from '../../../../utils/static/colors';

//ToDo: make extension validate
export const FileUploadForm = ({ form, label, required, question }) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    rules: {
      validate: (value) => {
        console.log(value);
        return true;
      }
    },
    defaultValue: null
  });

  return (
    <div>
      <UploadDocumentsBox
        label={label}
        required={required}
        extensions={question.availableExtensions}
        docs={field.value ? [field.value] : []}
        setDocs={(files) => field.onChange(files)}
      />

      {error && (
        <Body3Light color={COLORS.red} marginTop={1.5 * GU}>
          {error.message}
        </Body3Light>
      )}
    </div>
  );
};
