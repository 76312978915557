import React, { useState } from 'react';
import { DocumentLine, FileUploadButton } from '../../atoms';
import { Col, Container, DropDown, ExternalLink, Span, Label2, Row } from '../../../../ui';
import { DISPLAY_DOC_SUBTYPE } from '../../../../utils/static/kyc';
import { API_URL, COLORS } from '../../../../utils/static';
import { useTranslation } from 'react-i18next';

export const UploadDocumentsBox = (
  {
    label,
    extensions,
    required = false,
    docs,
    setDocs,
    types = null,
    setType,
    enableNumbering,
    multiple = false,
    hideLastBorder,
    buttonProps,
    template = null
  }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(docs ? new Array(docs.length).fill(1) : []);

  const addDocs = (selectedDocs) => {
    if (multiple) {
      const docsClone = [...docs];
      [...selectedDocs].forEach((item) => {
        docsClone.push(item);
      });

      setDocs(docsClone);
    } else {
      setDocs(selectedDocs[0]);
    }
  };

  const deleteDoc = (id) => {
    if (multiple) {
      setDocs(docs.filter((item, key) => key !== id));
    } else {
      setDocs(null);
    }
  };

  const handleSelected = (nextSelected, index) => {
    const clone = [...selected];
    clone[index] = nextSelected;
    setSelected(clone);
    setType(types[nextSelected], index);
  };

  return (
    <Container>
      {label && <Label2>
        {label}
        {required && <Span color={COLORS.aragonBlue}>&nbsp;*</Span>}
        {extensions && <Span color={COLORS.orange} marginLeft={8}>
          ({extensions.reduce((res, item, index) => res + (index > 0 ? ', ' : '') + item.label, '')})
        </Span>}
      </Label2>}

      {docs && docs.length > 0 && (
        <Container>
          {types
            ? docs.map((doc, key) => (
              <Row alignCenter margin={-8} cols={2} key={key}>
                <Col>
                  <DocumentLine
                    doc={{
                      name: doc.name,
                      documentURL: doc instanceof File ? URL.createObjectURL(doc) : doc,
                      id: key
                    }}
                    onDelete={(id) => deleteDoc(id)}
                    index={enableNumbering && key + 1}
                    hideLastBorder={hideLastBorder}
                    key={key}
                  />
                </Col>
                <Col>
                  <DropDown
                    items={types.map((item) => DISPLAY_DOC_SUBTYPE[item])}
                    selected={selected[key]}
                    onChange={(val) => handleSelected(val, key)}
                  />
                </Col>
              </Row>
            ))
            : docs.map((doc, key) => (
              <DocumentLine
                doc={{
                  name: doc.name,
                  documentURL: doc instanceof File ? URL.createObjectURL(doc) : doc,
                  id: key
                }}
                onDelete={(id) => deleteDoc(id)}
                index={enableNumbering && key + 1}
                hideLastBorder={hideLastBorder}
                key={key}
              />
            ))}
        </Container>
      )}

      <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '16px' }}>
        <FileUploadButton
          buttonProps={{ label: multiple ? 'Add files' : 'Add file', size: 'small', ...buttonProps }}
          onChange={addDocs}
          multiple={multiple}
        />
        {template && <ExternalLink href={`${API_URL}${template}`}>{t('Document template')}</ExternalLink>}
      </div>
    </Container>
  );
};
