import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../routing';
import { Button, Header as HeaderAragon } from '@aragon/ui';
import { ProfileViewer } from '../../../../components';
import { Row, Col, Link, Body2 } from '../../../../ui';
import styled from 'styled-components';
import { userActions } from '../../../../store/user';
import { COLORS } from '../../../../utils/static/colors';
import { useTranslation } from 'react-i18next';
import GlobalOutlined from '@ant-design/icons/lib/icons/GlobalOutlined';
import { Dropdown } from 'antd';
import { useQuery } from 'react-query';
import { investorLocalizationApi, investorLocalizationKeys } from '../../../../api/investor/localization';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import { queryClient } from '../../../../app/QueryClientProvider';
import { investorActions } from '../../../../store/investor';
import { adminLocalizationApi } from '../../../../api/admin/localization';
import { LANGUAGES } from '../../../../app/localization/static';

export const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, investor } = useSelector((state) => state);

  const onLogout = () => {
    dispatch(userActions.logout());
  };


  const { firstName, lastName, avatar, accountStatus, languageId } = investor;
  return (
    <Container>
      <StyledHeader
        wide={user?.userRole !== 'investor'}
        primary={
          user.userRole === 'investor' && (
            <Row margin={-6} alignCenter nowrap>
              <Col>
                <ProfileViewer
                  avatarUrl={avatar}
                  name={firstName || lastName ? `${firstName} ${lastName}` : t('Unnamed')}
                  size={'medium'}
                />
              </Col>
              {accountStatus === 'NOT_ACTIVE' && <Col>
                <Body2 color={COLORS.red} fontWeight={500}>{t('Blocked')}</Body2>
              </Col>}
            </Row>
          )
        }
        secondary={
          <Row margin={-10} alignCenter>
            {user && user.userRole === 'investor' && <LanguageInvestor languageId={languageId} />}
            {user && (user.userRole === 'admin' || user.userRole === 'superAdmin') &&
              <LanguageAdmin />
            }

            {user && user.userRole === 'investor' && <Link to={'/change-password'}>{t('Change password')}</Link>}
            <Col auto>
              {user.userRole ? (
                <Button label={t('Logout')} onClick={onLogout} />
              ) : (
                <Button label={t('Login')} onClick={() => history.push('/login')} />
              )}
            </Col>
          </Row>
        }
      />
    </Container>
  );
};

const LanguageInvestor = ({ languageId }) => {
  const { i18n } = useTranslation();
  const { data: languages } = useQuery({
    queryKey: investorLocalizationKeys.getAvailableLanguages,
    queryFn: investorLocalizationApi.getAvailableLanguages,
    select: data => data.data
  });

  useEffect(() => {
    try {
      if (languages) {
        const currentLang = languages?.find(i => i.code === i18n.language.toUpperCase());

        if (currentLang.id !== languageId) {
          setLanguage({ key: currentLang.id });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [languages]);

  const setLanguage = async (value) => {
    try {
      await investorLocalizationApi.setLanguage({ languageId: value.key });

      const newLang = languages.find(i => i.id === value.key)?.code?.toLowerCase();
      i18n.changeLanguage(newLang);

      localStorage.setItem('language', newLang);
      queryClient.refetchQueries();
    } catch (e) {

    }
  };


  if (!languages) return null;
  return (
    <Dropdown
      menu={{
        items: languages.map(item => ({
          key: item.id,
          label: item.language,
          icon: i18n.language === item.code.toLowerCase() ? <CheckOutlined /> : null
        })),
        onClick: setLanguage
      }}
      trigger={'click'}
    >
      <GlobalOutlined />
    </Dropdown>
  );
};

const LanguageAdmin = ( ) => {
  const { i18n } = useTranslation();

  const setLanguage = async (value) => {
    try {
      i18n.changeLanguage(value.key.toLowerCase());

      localStorage.setItem('language', value.key.toLowerCase());
      queryClient.refetchQueries();
    } catch (e) {

    }
  };


  return (
    <Dropdown
      menu={{
        items: LANGUAGES.map(item => ({
          key: item.code,
          label: item.language,
          icon: i18n.language === item.code.toLowerCase() ? <CheckOutlined /> : null
        })),
        onClick: setLanguage
      }}
      trigger={'click'}
    >
      <GlobalOutlined />
    </Dropdown>
  );
};

const StyledHeader = styled(HeaderAragon)`
  padding: 12px 0;
  ${p => !p.wide ? `
    max-width: 1200px;
    margin: 0 auto;
  ` : ''}
`;
const Container = styled.div`
  height: 64px;
  width: 100%;
  position: sticky;
  top: 0;
  margin-bottom: 1px;
  padding: 0 24px 0 16px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  z-index: 100;
`;
