import React, { useEffect, useMemo, useState } from 'react';
import { kycApi } from '../../api/kyc';
import { useForm } from 'react-hook-form';
import { Box, FooterButtons, RadioGroupForm } from '../../components';
import { Col, Container, Row } from '../../ui/layout';
import { Body1Light, Body2Light, Title2 } from '../../ui/typography';
import { history } from '../../routing';
import styled from 'styled-components';
import ConfigureStepsItem from '../../components/registrationComponents/StepsPanel/ConfigureStepItem';
import { COLORS } from '../../utils/static/colors';

const SHOWED_SECTIONS_COUNT = 3;
let appContainer = document.getElementById('app-container');
export const MifidQuestionnaire = ({ config, currentStep, setCurrentStep }) => {
  const { control, handleSubmit, reset } = useForm();
  const [sections, setSections] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);

  useEffect(() => {
    reset();
  }, [currentStep]);

  useEffect(() => {
    (async () => {
      if (!sections) {
        try {
          const res = await kycApi.getSectionsMifid('MIFID_GERMAN');
          setSections(res.data);
        } catch (e) {
          console.error('GET_SECTIONS', e);
        }
      }
    })();
  }, []);

  const stepsCount = useMemo(() => {
    if (sections) return Math.ceil(sections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [sections]);

  const onSubmit = async (data) => {
    if (currentSection + 1 < stepsCount) {
      setCurrentSection(currentSection + 1);
      if (!appContainer) {
        appContainer = document.getElementById('app-container');
      }
      appContainer.parentNode.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onBack = () => {
    if (currentSection > 0) setCurrentSection(currentSection - 1);
    else if (currentStep > 0) setCurrentStep(currentStep - 1);
    else history.goBack();
  };

  const startSlicePos = currentSection * SHOWED_SECTIONS_COUNT;
  return (
    <Container paddingY={64} paddingRight={23} paddingBottom={'0'} paddingLeft={15}>
      <Title2 textAlign={'center'} marginBottom={40}>
        {config[currentStep].title}
      </Title2>

      {sections &&
        sections.slice(startSlicePos, startSlicePos + SHOWED_SECTIONS_COUNT).map((sectionObj) => (
          <Box key={sectionObj.id}>
            <Body1Light>{sectionObj.section}</Body1Light>

            <Question section={sectionObj} control={control} />
          </Box>
        ))}

      {sections && (
        <StickyBox paddingY={32} paddingX={40}>
          <Row margin={-16} marginBottom={-2} nowrap>
            {new Array(stepsCount).fill(true).map((item, key) => (
              <Col key={key}>
                <ConfigureStepsItem
                  label={`Step ${key + 1}`}
                  currentStep={currentSection}
                  step={key}
                  stepNumber={key + 1}
                  titleProps={{ marginLeft: 14 }}
                />
                <ProgressLine active={currentSection > key} />
              </Col>
            ))}
          </Row>
          <FooterButtons onNext={handleSubmit(onSubmit)} onBack={onBack} />
        </StickyBox>
      )}
    </Container>
  );
};

const Question = ({ section, control }) => {
  return (
    <Container>
      {section.questions &&
        section.questions.map((questionObj) => (
          <QuestionWrapper key={questionObj.id} paddingY={24}>
            <Body2Light>{questionObj.question}</Body2Light>

            <RadioGroupForm
              items={questionObj.availableAnswers.map((item) => item.answer)}
              ids={questionObj.availableAnswers.map((item) => item.id)}
              form={{
                name: questionObj.id,
                control
                // rules: {required: 'Required'}
              }}
            />
          </QuestionWrapper>
        ))}
    </Container>
  );
};

const QuestionWrapper = styled(Container)`
  border-bottom: 1px solid #dde4e9;

  :last-child {
    padding-bottom: 0;
    border-bottom: unset;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const StickyBox = styled(Container)`
  background: #ffffff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
  position: sticky;
  bottom: 0;
  margin: 40px -23px 0 -15px;
  z-index: 22;
`;
const ProgressLine = styled.div`
  height: 6px;
  background: ${(p) => (p.active ? COLORS.aragonBlue : COLORS.darkBabyBlue)};
`;
