import React, { useEffect, useMemo, useState } from 'react';
import {verifyEmail, sendEmailCode } from '../../../../api';
import { RegContainer, StepsPanel, CodeCountdown, FooterButtons } from '../../../../components';
import styled from 'styled-components';
import { Input, LinkButton, Title2 } from '../../../../ui';
import { GU } from '@aragon/ui';
import { individualSteps } from '../../../../utils/static';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from '../../../../store/investor';
import { entitiesActions } from '../../../../store/entities';
import { useTranslation } from 'react-i18next';

export const Step2 = ({ stepNumber, goNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.investor);
  const [code, setCode] = useState('');

  useEffect(() => {
    handleSendCode();
  }, []);

  const handleSendCode = () => {
    sendEmailCode().then();
  };

  const handleNext = async () => {
    try {
      const res = await verifyEmail(code);

      console.log(res);
      if (res.status === 200) {
        const { verifiedEmail } = res.data;
        dispatch(investorActions.setVerifiedEmail(verifiedEmail));

        goNext();
      }
    } catch (e) {
      const errorMessage = e.response?.data.message;
      if (errorMessage === 'CONFIRM_CODE_IS_WRONG') {
        dispatch(entitiesActions.addToast(t('Wrong code')));
      }
    }
  };

  const disableButton = useMemo(() => {
    return !code;
  }, [code]);

  return (
    <StepsPanelContainer>
      <StepsPanel title={t('Registration')} step={stepNumber} steps={individualSteps.map((item) => t(item))} />

      <RegContainer type='centered'>
        <Title onClick={goNext}>{t('Verify your e-mail')}</Title>

        <Subtitle>
          {t('The verification code has been sent on your e-mail:')}
          <br />
          <LinkButton>{email}</LinkButton>
        </Subtitle>

        <Input
          label={t('Verification code')}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type={'number'}
          wide
        />

        <RepeatSendTitle>
          <CodeCountdown sendCode={sendEmailCode} isInitialCall={false}/>
        </RepeatSendTitle>

        <FooterButtons onNext={handleNext} nextDisabled={disableButton} hideBack />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;
const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
