import React, { useContext } from 'react';
import { ProfileReferralsView } from '../../../common';
import { useQuery } from 'react-query';
import { investorApi, investorKeys } from '../../../../api';
import { MessageContext } from '../../../../app/MessageProvider';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const ProfileReferrals = () => {
  const { t } = useTranslation();
  const investor = useSelector(state => state.investor);
  const messageApi = useContext(MessageContext);
  const refferals = useQuery({
    queryKey: investorKeys.getRefferalAccounts,
    queryFn: () => investorApi.getMyRefferalAccounts(investor.accountRole),
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Refferals fetch error'))
  });
  const reccomenders = useQuery({
    queryKey: investorKeys.getRecommendersAccounts,
    queryFn: investorApi.getMyRecommenderAccounts,
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Recommenders fetch error'))
  });
  const agents = useQuery({
    queryKey: investorKeys.getAgentAccounts,
    queryFn: investorApi.getMyAgentAccounts,
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Agents fetch error'))
  });


  return (
    <ProfileReferralsView
      referrals={{ list: refferals.data, error: refferals.isError, isLoading: refferals.isLoading }}
      recommeders={{ list: reccomenders.data, error: reccomenders.isError, isLoading: reccomenders.isLoading }}
      agents={{ list: agents.data, error: agents.isError, isLoading: agents.isLoading }}
    />
  );
};
