import React, { useMemo, useState } from 'react';
import { Box, InnerBox } from '../../../components/customAragon/Box';
import { Col, Row } from '../../../ui/layout';
import { Button } from '../../../ui/atoms/Button';
import { UploadFilesModal } from '../../../components/common/modals/UploadFiles';
import { dateString, getFileSrc } from '../../../utils/helpers/common';
import styled from 'styled-components';
import { ExternalLink } from '../../../ui/atoms/Link';
import { useTranslation } from 'react-i18next';

export const DeepCheckView = ({ viewMode, deepCheckData, initCheck, uploadReport }) => {
  const { t } = useTranslation();
  const [uploadFileId, setUploadFileId] = useState(-1);

  const deepCheck = useMemo(() => {
    if (deepCheckData) {
      return {
        kycPending: deepCheckData.fullKyc?.lastPending,
        kycChecked: deepCheckData.fullKyc?.lastChecked,
        sanctionsPending: deepCheckData.sanctions?.lastPending,
        sanctionsChecked: deepCheckData.sanctions?.lastChecked
      };
    }

    return {};
  }, [deepCheckData]);

  return (
    <Box paddingY={8}>
      <InnerBox>
        {deepCheck.kycPending && (
          <CheckRequestRow>
            {t('Requested by')} {deepCheck.kycPending.adminEmail}
            <strong> {dateString(new Date(deepCheck.kycPending.createdAt))}</strong>
          </CheckRequestRow>
        )}

        <Row justifyContent={'space-between'}>
          <Col>
            {t('Deep KYC status')}:&ensp;
            {deepCheck.kycChecked ? (
              <ExternalLink href={getFileSrc(deepCheck.kycChecked?.documentURL)}>
                {t('Report from')} {dateString(new Date(deepCheck.kycChecked.updatedAt))}
              </ExternalLink>
            ) : (
              <strong>{t('N/A')}</strong>
            )}
          </Col>

          {viewMode === 'admin' && (
            <Button
              label={t('Request deep KYC')}
              onClick={() => initCheck('FULL_KYC')}
              disabled={deepCheck.kycPending}
              size={'small'}
              minWidth={200}
            />
          )}
          {viewMode === 'superAdmin' && (
            <Col auto style={{ textAlign: 'right' }}>
              <Button
                label={t('Upload report for deep KYC')}
                onClick={() => setUploadFileId(deepCheck.kycPending?.id)}
                disabled={!deepCheck.kycPending}
                size={'small'}
                minWidth={260}
              />
            </Col>
          )}
        </Row>
      </InnerBox>

      <InnerBox>
        {deepCheck.sanctionsPending && (
          <CheckRequestRow>
            {t('Requested by')} {deepCheck.sanctionsPending.adminEmail}
            <strong> {dateString(new Date(deepCheck.sanctionsPending.createdAt))}</strong>
          </CheckRequestRow>
        )}

        <Row justifyContent={'space-between'}>
          <Col style={{ whiteSpace: 'pre-wrap' }}>
            {t('Sanctions check status')}:&ensp;
            {deepCheck.sanctionsChecked ? (
              <ExternalLink href={getFileSrc(deepCheck.sanctionsChecked?.documentURL)}>
                {t('Report from')} {dateString(new Date(deepCheck.sanctionsChecked.updatedAt))}
              </ExternalLink>
            ) : (
              <strong>{t('N/A')}</strong>
            )}
          </Col>

          {viewMode === 'admin' && (
            <Button
              label={t('Sanctions check')}
              onClick={() => initCheck('SANCTIONS')}
              disabled={deepCheck.sanctionsPending}
              size={'small'}
              minWidth={200}
            />
          )}
          {viewMode === 'superAdmin' && (
            <Col auto style={{ textAlign: 'right' }}>
              <Button
                label={t('Upload report for sanctions check')}
                onClick={() => setUploadFileId(deepCheck.sanctionsPending?.id)}
                disabled={!deepCheck.sanctionsPending}
                size={'small'}
                minWidth={260}
              />
            </Col>
          )}
        </Row>
      </InnerBox>

      <UploadFilesModal
        visible={uploadFileId !== -1}
        onClose={() => setUploadFileId(-1)}
        onSubmit={(files) => uploadReport({ checkId: uploadFileId, file: files[0] })}
      />
    </Box>
  );
};

const CheckRequestRow = styled(Row)`
  justify-content: flex-end;
  white-space: pre-wrap;
  margin-bottom: 8px;
`;
