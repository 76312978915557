import axios from 'axios';
import { API_URL } from '../../../utils/static/api';


// role: [AGENT, RECOMMENDER]
// requestType: [ROLE, REFERRAL_CODE]


const setRefferalCode = ({ investorId, code, role }) => {
  switch (role) {
    case 'AGENT': {
      return axios.post(`${API_URL}/api/kyc/v1/admin/referralCodes`, {
        investorId,
        referralCodeAgent: code
      });
    }
    case 'RECOMMENDER': {
      return axios.post(`${API_URL}/api/kyc/v1/admin/referralCodes`, {
        investorId,
        referralCodeRecommender: code
      });
    }
  }
};

const getInvestorRequest = ({ investorId, role, requestType }) => (
  axios.get(`${API_URL}/api/kyc/v1/admin/requestCheck`, { params: { investorId, role, requestType } })
);

const resolveRequest = (id) => (
  axios.post(`${API_URL}/api/kyc/v1/admin/requestCheck`, { requestCheckId: id })
);


export const adminReferralApi = {
  setRefferalCode,
  getInvestorRequest,
  resolveRequest
};

export const adminReferralApiKeys = {
  getInvestorRequest: 'adminReferralApi.getInvestorRequest'
};
