import Dashboard from '../../../../assets/icons/sidebar/dashboard.svg';
import Assets from '../../../../assets/icons/sidebar/assets.svg';
import Orders from '../../../../assets/icons/sidebar/orders.svg';
import Wallet from '../../../../assets/icons/sidebar/wallet.svg';
import Deals from '../../../../assets/icons/sidebar/deals.svg';
import Reporting from '../../../../assets/icons/sidebar/reporting.svg';
import WishList from '../../../../assets/icons/sidebar/wishlist.svg';
import Profile from '../../../../assets/icons/sidebar/profile.svg';
import Investors from '../../../../assets/icons/sidebar/investors.svg';
import Issues from '../../../../assets/icons/sidebar/issuers.svg';
import Funds from '../../../../assets/icons/sidebar/funds.svg';
import Settings from '../../../../assets/icons/sidebar/settings.svg';

export const SideBarConfigInvestor = [
  { title: 'Dashboard', icon: Dashboard, href: '/dashboard' },
  { title: 'Assets', icon: Assets, href: '/assets' },
  { title: 'Orders', icon: Orders, href: '/orders' },
  { title: 'Wallet', icon: Wallet, href: '/wallet' },
  { title: 'Deals', icon: Deals, href: '/deals' },
  // {title: 'Reporting', icon: Reporting, href: '/'},
  // {title: 'Wish List', icon: WishList, href: '/wishlist'},
  { title: 'Profile', icon: Profile, href: '/profile' }
];

export const SideBarConfigAdmin = [
  // {title: 'Home', icon: Dashboard, href: '/home'},
  {
    title: 'User management',
    icon: Investors,
    href: '/investors/PERSONAL',
    subpages: [
      { title: 'Individual clients', icon: Issues, href: '/investors/PERSONAL' },
      { title: 'Legal entities', icon: Deals, href: '/investors/COMPANY' }
    ]
  },
  {
    title: 'Localization',
    // icon: Investors,
    href: '/localization',
  }

  // {title: 'Assets', icon: Assets, href: '/assets'},
  // {title: 'Funds', icon: Funds, href: '/funds'},
  // {title: 'Reporting', icon: Reporting, href: '/reporting'},
  // {title: 'Management', icon: Settings, href: '/management'},
  // {title: 'Orders', icon: Orders, href: '/orders'},
];
export const SideBarConfigSuperAdmin = [
  {
    title: 'User management',
    icon: Investors,
    href: '/investors/PERSONAL',
    subpages: [
      { title: 'Individual clients', icon: Issues, href: '/investors/PERSONAL' },
      { title: 'Legal entities', icon: Deals, href: '/investors/COMPANY' }
    ]
  }
];
