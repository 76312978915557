import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GU } from '@aragon/ui';
import { DeepCheckModule, EditProfileInfo } from '../../../../../modules';
import { ProfileViewer, Box, InnerBox, BoxLine } from '../../../../../components';
import { Col, Row, Title4 } from '../../../../../ui';
import { history } from '../../../../../routing';
import { ENABLED_STATUSES_INVESTOR } from '../../../../../utils/static/kyc';
import { COLORS, DISPLAY_INVESTOR_ACCOUNT_STATUS } from '../../../../../utils/static';
import { dateString } from '../../../../../utils/helpers';
import styled from 'styled-components';
import { Modal, Steps, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { venomApi } from '../../../../../api/venom';
import { IDENTIX_URL } from '../../../../../utils/static/url';
import { MessageContext } from '../../../../../app/MessageProvider';

const steps = ['Tier 1', 'Tier 2', 'Tier 3'];

export const ProfileInfoView = (
  {
    investor,
    isEdit,
    saveTab,
    saveCallback,
    viewMode = 'investor'
  }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const messageApi = useContext(MessageContext);

  if (investor) {
    const {
      id,
      avatar,
      firstName,
      lastName,
      citizenship,
      phone,
      email,
      tier,
      accountStatus,
      createdAt,
      lastSession,
      scoringPoints,
      referralCode,
      venomPublicKey
    } = investor;

    const issueVc = async () => {
      try {
        setLoading(true);
        await venomApi.issueVC(venomPublicKey);
        setPopup(true);
      } catch (e) {
        let error = e.response?.data?.message === 'NOT_VERIFY' ? 'Re-Kyc VC available only for questionnaire in "passed" status' : 'Something went wrong';
        messageApi.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (!isEdit) {
      return (
        <div>
          <Modal
            title={'Your ReKYC VC is issued. Check it in Identix Pass'}
            open={popup}
            okText={'Go to Identix Pass'}
            cancelText={'Dismiss'}
            onCancel={() => setPopup(false)}
            onOk={() => window.open(IDENTIX_URL)}
          />

          <Box padding={0} innerPadding={2 * GU} borderRadius={12}>
            {viewMode === 'investor' && (
              <InnerBox>
                <Title4 marginBottom={16}>{t('Tier progress')}</Title4>

                <ProgressContainer>
                  <Steps
                    current={tier - 1}
                    items={steps.map(item => ({ key: item, title: t(item) }))}
                  />
                </ProgressContainer>
              </InnerBox>
            )}

            <InnerBox>
              <Row margin={-16}>
                <Col width={viewMode === 'admin' ? '56%' : '100%'}>
                  {viewMode === 'investor' && <Title4 marginBottom={16}>{t('Personal data')}</Title4>}
                  <ProfileViewer
                    avatarUrl={avatar && avatar}
                    name={`${firstName} ${lastName}`}
                    type='short'
                    marginBottom={16}
                  />

                  <BoxLine title={t('Phone number')} data={phone} />
                  <BoxLine title={t('E-mail')} data={email} />
                  <BoxLine title={t('Citizenship')} data={citizenship?.country} />
                  {viewMode === 'investor' && venomPublicKey && (
                    <BoxLine title={t('Venom public key')} data={venomPublicKey} />
                  )}
                  {viewMode === 'investor' && venomPublicKey && (
                    <BoxLine>
                      <Button onClick={issueVc} type="primary" loading={loading}>Issue a KYC VC with this data</Button>
                    </BoxLine>
                  )}
                </Col>
                {viewMode === 'admin' && (
                  <Col width={'44%'} style={{ borderLeft: `1px solid ${COLORS.greyBasic}` }}>
                    <InnerBox>
                      {t('Status')}: <strong>{DISPLAY_INVESTOR_ACCOUNT_STATUS[accountStatus]}</strong>
                    </InnerBox>
                    <InnerBox>
                      <div>
                        {t('Personal ID')}: <strong>{id}</strong>
                      </div>
                      <div>
                        {t('Registered')}:{' '}
                        <strong>{createdAt ? dateString(new Date(createdAt)) : '-'}</strong>
                      </div>
                      <div>
                        {t('Last session')}:{' '}
                        <strong>{lastSession ? dateString(new Date(lastSession)) : '-'}</strong>
                      </div>
                      <div>
                        {t('Tier level')}: <strong>{t('Tier')} {tier}</strong>
                      </div>
                      <div>
                        {t('Scoring points')}: <strong>{scoringPoints}</strong>
                      </div>
                    </InnerBox>
                    <InnerBox>{t('Legal entity connections')}: {t('none')}</InnerBox>
                  </Col>
                )}
              </Row>
            </InnerBox>

            {viewMode === 'investor' && referralCode && <InnerBox>
              <BoxLine title={t('Referral code')}>
                <Typography.Text copyable
                                 style={{ fontSize: '16px' }}>{referralCode}</Typography.Text>
              </BoxLine>
            </InnerBox>}
          </Box>

          {(viewMode === 'admin' || viewMode === 'superAdmin') && (
            <DeepCheckModule investorId={id} viewMode={viewMode} />
          )}

          {investor.additionalInfo && (
            <Box heading={''} borderRadius={12}>
              <Row marginBottom={16} margin={-24}>
                <Title4>{t('Additional information')}</Title4>
                {viewMode === 'investor' &&
                ENABLED_STATUSES_INVESTOR[investor?.additionalInfo?.status] && (
                  <Button
                    label={`${t('Fill')} ${
                      investor?.account ? t(`${investor.account.toLowerCase()} data`) : ''
                    } ${t('questionnaire')}`}
                    onClick={() => {
                      history.push(
                        `/questionnaire/${investor?.additionalInfo?.id}`,
                        {
                          prevPath: location.pathname,
                          nextState: { lastProfileTab: investor.lastProfileTab }
                        });
                    }}
                    size={'small'}
                    mode={'strong'}
                    disabled={accountStatus !== 'ACTIVE'}
                  />
                )}
              </Row>

              {investor.additionalInfo.answers.map((section, key) => (
                <InfoSection>
                  {section.answers.map((item, key2) => (
                    <BoxLine title={item.title} data={item.answer || '-'} type={item.type} key={key2} />
                  ))}
                </InfoSection>
              ))}
            </Box>
          )}
        </div>
      );
    } else {
      return <EditProfileInfo investor={investor} saveTab={saveTab} saveCallback={saveCallback} />;
    }
  }

  return null;
};


const ProgressBarContainer = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
`;
const ProgressContainer = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
`;
const Bullet = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: ${p => p.active ? COLORS.mintGreen : COLORS.darkBabyBlue};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    `;
const Bullet1 = styled(Bullet)`
    `;
const Bullet2 = styled(Bullet)`
    left: 50%;
    transform: translate(-50%, -50%);
    `;
const Bullet3 = styled(Bullet)`
    left: 100%;
    transform: translate(-100%, -50%);
    `;
const InfoSection = styled.div`
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e7e7e7;
  
  &:nth-last-child(1) {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;