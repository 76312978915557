import React from 'react';
import { GU, BackButton as ButtonBack } from '@aragon/ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const BackButton = (props) => {
  const { t } = useTranslation();

  return <StyledBackButton {...props} label={t('Back')} />;
};

const StyledBackButton = styled(ButtonBack)`
  // margin-top: ${4 * GU}px;
  min-width: ${12 * GU}px;
  height: ${5 * GU}px;
  border: 1px solid #dde4e9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  padding: 0 12px;
  justify-content: center;
  ${(p) => (p.hide ? 'visibility: hidden;' : '')}
  & > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
  }
`;
