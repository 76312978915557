import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { adminReferralApi, adminReferralApiKeys } from '../../../api/admin/referrals';
import { useSelector } from 'react-redux';
import { Box } from '../../../components/customAragon/Box';
import { Button, Col, Input, Row, Typography } from 'antd';
import { AntdTypographyWrapper } from '../../../ui/typography';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../ui/layout';
import { MessageContext } from '../../../app/MessageProvider';
import { queryClient } from '../../../app/QueryClientProvider';

// "agent": {
//   "id": "37",
//     "firstName": "agent",
//     "lastName": "2"
// }

// "recommender": {
//   "id": "40",
//     "firstName": "assistant",
//     "lastName": "ass"
// }

export const InvestorReferralRequests = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { investor } = useSelector(state => state);
  const [code, setCode] = useState('');
  const [codeOpened, setCodeOpened] = useState(false);
  const messageApi = useContext(MessageContext);

  const { data: agentRequest } = useQuery({
    queryKey: [adminReferralApiKeys.getInvestorRequest, 'ROLE', 'AGENT', id],
    queryFn: () => adminReferralApi.getInvestorRequest({
      investorId: +id,
      role: 'AGENT',
      requestType: 'ROLE'
    }),
    select: data1 => data1.data
  });
  const { data: assistantRequest } = useQuery({
    queryKey: [adminReferralApiKeys.getInvestorRequest, 'ROLE', 'RECOMMENDER', id],
    queryFn: () => adminReferralApi.getInvestorRequest({
      investorId: +id,
      role: 'RECOMMENDER',
      requestType: 'ROLE'
    }),
    select: data1 => data1.data
  });
  const { data: codeRequest } = useQuery({
    queryKey: [adminReferralApiKeys.getInvestorRequest, 'REFERRAL_CODE', id],
    queryFn: () => adminReferralApi.getInvestorRequest({
      investorId: +id,
      role: investor.accountRole,
      requestType: 'REFERRAL_CODE'
    }),
    select: data1 => data1.data,
    enabled: Boolean(investor.accountRole)
  });


  const updateReferralCode = async () => {
    try {
      await adminReferralApi.setRefferalCode({ investorId: +id, role: investor.accountRole, code: code });
      if (codeRequest && codeRequest.id) {
        await adminReferralApi.resolveRequest(codeRequest.id);
      }

      await queryClient.refetchQueries([adminReferralApiKeys.getInvestorRequest, 'REFERRAL_CODE']);
      setCode('');
      setCodeOpened(false);
      messageApi.success(t('Referral code successfully changed'));
    } catch (e) {
      messageApi.error(t('Something went wrong'));
    }
  };

  const approveAgentRequest = async () => {
    try {
      await adminReferralApi.resolveRequest(agentRequest.id);

      await queryClient.refetchQueries([adminReferralApiKeys.getInvestorRequest, 'ROLE']);
      messageApi.success(t('Agent request successfully approved'));
    } catch (e) {
      console.error(e);
      messageApi.error(t('Something went wrong'));
    }
  };

  const approveAssistantRequest = async () => {
    try {
      await adminReferralApi.resolveRequest(assistantRequest.id);

      await queryClient.refetchQueries([adminReferralApiKeys.getInvestorRequest, 'ROLE']);
      messageApi.success(t('Assistant request successfully approved'));
    } catch (e) {
      console.error(e);
      messageApi.error(t('Something went wrong'));
    }
  };


  console.log(agentRequest)
  return (
    <AntdTypographyWrapper>
      <Box>
        <Row wrap={false} gutter={24}>
          {investor.accountRole && <Col span={8}>
            <Typography.Title level={4}>
              {t('Set referral code')}
              {codeRequest && <Typography.Text type={'warning'} style={{ marginLeft: '12px' }}>
                {t('Requested')}
              </Typography.Text>}
            </Typography.Title>


            {!codeOpened && <Button onClick={() => setCodeOpened(true)} type={'primary'}>
              {t('Set code')}
            </Button>}

            {codeOpened && <Container marginTop={20}>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={t('Enter code')}
              />
              <Row gutter={8} style={{ marginTop: '16px' }}>
                <Col>
                  <Button onClick={() => setCodeOpened(false)}>
                    {t('Cancel')}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={updateReferralCode} type={'primary'}>
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
            </Container>}
          </Col>}

          <Col span={investor.accountRole ? 8 : 12}>
            <Typography.Title level={4}>
              {t('Approve assistant')}
              {assistantRequest && <Typography.Text type={'warning'} target={'_blank'} style={{ marginLeft: '8px' }}>
                {t('Requested')}
              </Typography.Text>}
            </Typography.Title>

            {assistantRequest && assistantRequest.recommender && <Typography.Paragraph>
              {t('Requested assistant')}:
              <Typography.Link
                href={`${window.location.origin}/investor/${assistantRequest.recommender.id}`}
                target={'_blank'}
                style={{ marginLeft: '6px' }}
              >
                {assistantRequest.recommender.firstName} {assistantRequest.recommender.lastName}
              </Typography.Link>
            </Typography.Paragraph>}

            <Button onClick={approveAssistantRequest} type={'primary'} disabled={!assistantRequest}>
              {t('Approve')}
            </Button>
          </Col>

          <Col span={investor.accountRole ? 8 : 12}>
            <Typography.Title level={4}>
              {t('Approve agent')}
              {agentRequest && <Typography.Text type={'warning'} style={{ marginLeft: '12px' }}>
                {t('Requested')}
              </Typography.Text>}
            </Typography.Title>

            {agentRequest && agentRequest.agent && <Typography.Paragraph>
              {t('Requested agent')}:
              <Typography.Link
                href={`${window.location.origin}/investor/${agentRequest.agent.id}`}
                target={'_blank'}
                style={{ marginLeft: '6px' }}
              >
                {agentRequest.agent.firstName} {agentRequest.agent.lastName}
              </Typography.Link>
            </Typography.Paragraph>}

            <Button onClick={approveAgentRequest} type={'primary'} disabled={!agentRequest}>
              {t('Approve')}
            </Button>
          </Col>
        </Row>
      </Box>


    </AntdTypographyWrapper>
  );
};
