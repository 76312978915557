import styled from 'styled-components';
import { GU } from '@aragon/ui';
import { COLORS } from '../../utils/static';

export const Title1 = styled.h1`
  font-size: 32px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: #000000;
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
  ${(p) => (p.textAlign ? `text-align: ${p.textAlign}` : '')}
`;
export const Title2 = styled.h2`
  font-size: 26px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  color: #000000;
  ${(p) => (p.marginBottom ? `margin-bottom: ${p.marginBottom}px;` : '')}
  ${(p) => (p.marginTop ? `margin-top: ${p.marginTop}px;` : '')}
    ${(p) => (p.textAlign ? `text-align: ${p.textAlign}` : '')}
`;
export const Title3 = styled.h3`
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
  color: ${(p) => (p.color ? p.color : '#000000')};
  ${(p) => (p.textAlign ? `text-align: ${p.textAlign}` : '')}
`;
export const Title4 = styled.h4`
  font-size: 20px;
  font-weight: ${(p) => (p.weight ? p.weight : '400')};
  line-height: 1.5;
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
  color: ${(p) => (p.color ? p.color : '#000000')};
`;
export const Title5 = styled.h5`
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
  margin: 0;
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '0')};
  color: ${(p) => (p.color ? p.color : '#000000')};
`;
export const Label2 = styled.label`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #637381;
  text-align: left;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : `${0.5 * GU}px`)};
  text-transform: uppercase;
`;
export const RegTitle = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;

export const Text = styled.div`
  color: ${({ color }) => (color ? color : '#212B36')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0')};
  margin-right: ${(p) => (p.marginRight ? `${p.marginRight}px` : '0')};
  padding: ${(p) => (p.padding ? `${p.padding}px` : '0')};
  ${(p) => (p.paddingTop ? `padding-top: ${p.paddingTop}px` : '')};
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight}` : '')};
  ${p => p.inline ? `display: inline-block;` : ''}
  ${p => p.textInline ? `display: inline;` : ''}
  ${p => p.preWrap ? `white-space: pre-wrap;` : ''}
`;
const MaisonTemplate = styled(Text)`
  font-family: MaisonNeue;
  color: ${({ color }) => (color ? color : 'rgba(0, 0, 0, .75)')};
`;
const RobotoTemplate = styled(Text)`
  font-family: 'Roboto Mono', monospace;
  color: ${(p) => (p.color ? p.color : COLORS.aragonBlue)};
`;

export const Body1Light = styled(Text)`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
`;

export const Body2Light = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
`;

export const Body2 = styled(Text)`
  font-size: 16px;
  line-height: 22px;
`;

export const Body3Light = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

export const Body4Light = styled(Text)`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

export const Body4 = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`;

export const Label = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : '#637381')};
  margin-top: ${(p) => (p.marginTop ? `${p.marginTop}px` : '0')};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '0')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0')};
`;

// Maison
export const MaisonBody1 = styled(MaisonTemplate)`
  font-size: 15px;
  line-height: 22px;
`;

export const MaisonBody2 = styled(MaisonTemplate)`
  font-size: 13px;
`;

// Roboto
export const RobotoBody4 = styled(RobotoTemplate)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

export const AntdTypographyWrapper = styled.div`
  font-weight: 400;
`;


export const Span = styled.span`
  ${(p) => (p.color ? `color: ${p.color};` : '')}
  ${(p) => (p.marginLeft ? `margin-left: ${p.marginLeft}px;` : '')}
`;

export const AntWrapper = styled.div`
  font-weight: 400;
`;
