import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

//role: [AGENT, RECOMMENDER]

export const getMyRefferalCode = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/referralCode`)
);

export const generateMyRefferalCode = () => (
  axios.post(`${API_URL}/api/kyc/v1/investor/referralCode`)
);

export const getMyRefferalAccounts = () => (
  axios.get(`${API_URL}/api/kyc/v1/recommender/investors`)
);

export const getMyRecommenderAccounts = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/recommenders`)
);

export const getMyAgentAccounts = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/agents`)
);

export const setRefferalCode = (code) => (
  axios.post(`${API_URL}/api/kyc/v1/investor/setReferralCode`, { referralCode: code })
);

const requestRefferalCode = (role) => (
  axios.post(`${API_URL}/api/kyc/v1/investor/requestCheckReferralCode`, { role })
);
const requestSetAgent = (code) => (
  axios.post(`${API_URL}/api/kyc/v1/investor/requestCheckRole`, { role: 'AGENT', referralCode: code })
);
const requestSetAssistant = (code) => (
  axios.post(`${API_URL}/api/kyc/v1/investor/requestCheckRole`, { role: 'RECOMMENDER',  referralCode: code})
);

export const investorReferralApi = {
  requestRefferalCode,
  requestSetAgent,
  requestSetAssistant
};

